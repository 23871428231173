<template>
  <WsMain>
    <WsReadSection
      :id="_id"
      :modelName="modelName"
      :label="label"
      :fields="fields"
      :leftFields="leftFields"
      :rightFields="_rightFields"
      :labelInLocale="true"
      v-model="modelData"
    ></WsReadSection>
    <WsInfoTulpaSections :value="_sectionContent" />
  </WsMain>
</template>

<script>
import model from "@/__vue2stone_cms/models/tulpa_page";
import S_App_Auth from "@/__vue2stone_cms/service/app/auth";
export default {
  data: () => ({
    modelName: model.modelName,
    label: model.label,
    fields: model.fields,
    leftFields: ["name", "route", "title", "description", "og_image"],
    modelData: null,
    state: {
      content: {},
    },
  }),

  computed: {
    _rightFields() {
      const _rightFields = [
        "tulpa_page_template",
        "tulpa_cross_items",
        "is_active",
        "canonical_url",
        "tags",
        "remark",
      ];
      if (S_App_Auth.hasScope(["boss"])) {
        if (this.$config.auth.admin_group) {
          if (this.$config.auth.admin_blur) {
            _rightFields.push("cmser_groups");
          } else {
            _rightFields.push("admin_groups");
          }
        }
      }
      return _rightFields;
    },
    _sectionContent() {
      if (!this.modelData) {
        return null;
      }
      if (this.modelData.tulpa_page_template) {
        const _sectionContent = {};
        const _tulpaSections =
          this.modelData.tulpa_page_template.tulpa_sections;
        for (const contentKey in this.modelData.tulpa_page_template.content) {
          const contentItem =
            this.modelData.tulpa_page_template.content[contentKey];
          const _tulpaSection = _tulpaSections.find((e) => {
            return e.id == contentItem.id;
          });
          const _defaultContent = {};
          for (let key in _tulpaSection.content) {
            const _tulpaContentItem = contentItem.content[key];
            const _sectionContentItem = _tulpaSection.content[key];
            if (_tulpaContentItem == null || _tulpaContentItem.length === 0) {
              _defaultContent[key] = _sectionContentItem;
            } else {
              _defaultContent[key] = _tulpaContentItem;
            }
          }
          _sectionContent[contentKey] = {
            id: contentItem.id,
            content: this.modelData.content[contentKey]
              ? this.modelData.content[contentKey].content
              : {},
            name: _tulpaSection.name,
            fields: _tulpaSection.fields,
            defaultContent: _defaultContent,
          };
        }
        return _sectionContent;
      } else {
        const _sectionContent = {};
        const content = this.modelData.content;
        const tulpa_sections = this.modelData.tulpa_sections;
        for (const key in content) {
          const contentItem = content[key];

          const _tulpaSection = tulpa_sections.find((e) => {
            return e.id == contentItem.id;
          });
          _sectionContent[key] = {
            id: contentItem.id,
            content: contentItem.content,
            name: _tulpaSection.name,
            fields: _tulpaSection.fields,
            defaultContent: _tulpaSection.content,
          };
        }
        return _sectionContent;
      }
    },
    _id() {
      return this.$route.params.id;
    },
  },
};
</script>

<style>
</style>